/* eslint-disable func-names, prefer-rest-params, @typescript-eslint/no-unnecessary-condition */

/**
 * Hacks the DOM Node API to support auto-translators like Google Translate in React.
 * @see https://drukwerkdeal.atlassian.net/browse/SE-108
 * @see https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
const supportAutoTranslators = (): void => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild as <T extends Node>(child: T) => T;
    Node.prototype.removeChild = function <T extends Node>(child: T): T {
      if (child.parentNode !== this) {
        return child;
      }
      return originalRemoveChild.apply(this, arguments as unknown as [T]) as T;
    };

    const originalInsertBefore = Node.prototype.insertBefore as <T extends Node>(newNode: T, referenceNode: Node) => T;
    Node.prototype.insertBefore = function <T extends Node>(newNode: T, referenceNode: Node): T {
      if (referenceNode && referenceNode.parentNode !== this) {
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments as unknown as [T, Node]) as T;
    };
  }
};

export default supportAutoTranslators;
