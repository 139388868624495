import type { ExtendedCart } from '@printdeal/checkout-shared/';
import type { ID } from '@segment/analytics-next';
import { getCategoryName } from '@printdeal/business-logic';
import { getFromLocalStorage, LS_KEYS, parseStringifiedValue } from '../../helpers/window';
import type { AnalyticsTracker, TrackingData } from './AnalyticsTrackerContext';
import { RecommendationsConfig } from '../../types/Configuration';
import type { ConsentData } from '../../helpers/CookiebotHelper';
import type { ProductInfo } from '../../types/design';
import { getConsentData } from '../../helpers/CookiebotHelper';
import type Order from '../../types/order/Order';
import { type RecommendationProductDetailPage } from '../recommendations/types';

interface DatalayerProducts {
  name: string | undefined;
  id: string;
  dimension39: string | undefined;
  category: string;
  list: string | undefined;
  position: string;
}

export type EventType = typeof EVENT_TYPES_FOR_CT_RECOMMENDATIONS[keyof typeof EVENT_TYPES_FOR_CT_RECOMMENDATIONS];

export interface EventDataPayload {
  event: string;
  anonymousId?: string;
  label?: string;
  products?: DatalayerProducts[];
  locale?: string;
  tenant?: string;
  userId?: string | undefined | null;
  recommendationsMetadata?: {
    recommendation_id?: string;
    placement_id?: string;
  }
}

interface EvenPayloadClicked extends EventDataPayload {
  product_id?: string;
  category_id?: string;
}

interface PayloadForProductAdded {
  [index: string]: string | number | object | undefined | ID
}

export const EVENT_TYPES_FOR_CT_RECOMMENDATIONS = {
  LIST_VIEWED: 'Recommendation List Viewed',
  PRODUCT_CLICKED: 'Recommendation Clicked',
  PRODUCT_VIEWED: 'Product Viewed',
  PRODUCT_LIST_VIEWED: 'Product List Viewed',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_REMOVED: 'Product Removed',
  CART_VIEWED: 'Cart Viewed',
  CHECKOUT_STARTED: 'Checkout Started',
  CHECKOUT_STEP_VIEWED: 'Checkout Step Viewed',
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  ORDER_CREATED: 'Order Created',
  CATEGORY_LIST_VIEWED: 'Category List Viewed',
};

const PAGE_LABEL = {
  home: 'Home Page',
};

const anonymousId = getFromLocalStorage(LS_KEYS.ANONYMOUS_ID);
/**
 * ajs_user_id is set by segment in the LS. The value is either account.id or null
 */
const ajsUserId = parseStringifiedValue(getFromLocalStorage(LS_KEYS.AJS_USER_ID));

/**
* Event published to Segment for Recommendation Engine not for traits
* track() method is exposed to window using @segment/analytics-next npm package
*/
export const publishEventToSegment = (
  event: EventType,
  eventPayload: EventDataPayload | EvenPayloadClicked | PayloadForProductAdded,
) => {
  if (typeof window !== 'undefined' && window?.analytics?.track) {
    window.analytics.track(event, eventPayload);
  }
};

export const trackingDataExistsForId = (
  data: AnalyticsTracker['ctRecommendations'],
  placementId: string | undefined,
) => data.length > 0 && data.filter((item) =>
  item?.trackingIds?.placementId === placementId).length > 0;

export const findIndexForPlacementId = (
  data: AnalyticsTracker['ctRecommendations'],
  placementId: string | undefined,
) => data.findIndex((item) => item?.trackingIds?.placementId === placementId);

export const preparePayloadForListViewed = (
  recommendationId: string | undefined,
  placementId: string | undefined,
  products: DatalayerProducts[],
  config: RecommendationsConfig | null,
  consentData: ConsentData | undefined,
) => ({
  event: EVENT_TYPES_FOR_CT_RECOMMENDATIONS.LIST_VIEWED,
  ...(!consentData?.statistics && { anonymousId }),
  label: PAGE_LABEL.home,
  products,
  locale: config?.locale,
  tenant: config?.tenant,
  ...(consentData?.statistics && { userId: config?.customerId }),
  recommendationsMetadata: {
    recommendation_id: recommendationId,
    placement_id: placementId,
  },
});

export const preparePayloadForProductClicked = (
  recommendationId: string | undefined,
  product: RecommendationProductDetailPage,
  analyticsId: string | undefined,
  productPosition: number,
  placementId: string | undefined,
  config: RecommendationsConfig | null,
  consentData: ConsentData | undefined,
) => ({
  event: EVENT_TYPES_FOR_CT_RECOMMENDATIONS.PRODUCT_CLICKED,
  tenant: config?.tenant,
  locale: config?.locale,
  list_id: analyticsId,
  position: productPosition,
  product_id: product.contentful_id,
  prUUID: product.contentful_id,
  name: product.productName,
  ...(!consentData?.statistics && { anonymousId }),
  ...(consentData?.statistics && { userId: config?.customerId }),
  recommendationsMetadata: {
    recommendation_id: recommendationId,
    placement_id: placementId,
  },
});

/**
 *
 * @param trackerData ct recommendations from the AnalyticsTracker Context
 * @param productId
 * @param trailName Name of the event trail productsClicked' | 'productsAddedToCart' | 'orderCreatedForProducts
 * @returns whether AnalyticsTracker Context has the product trail saved for the session, for a product id
 */
export const findListIdforRecommendedProduct = (
  trackerData: AnalyticsTracker['ctRecommendations'],
  productId: string,
  trailName: 'productsClicked' | 'productsAddedToCart' | 'orderCreatedForProducts',
) => trackerData.filter((item) => new Set(item?.productIdsForEventsLogged?.[trailName])?.has(productId));

/**
 *
 * @param state old state
 * @param indexOfDataToBeUpdated index of the placement to be updated
 * @param dataToBeUpdated Payload from action
 * @param productId
 * @param trailName Name of the event trail productsClicked' | 'productsAddedToCart' | 'orderCreatedForProducts
 * @returns a new state
 * used as a reducer helper in the AnalyticsTracker Provider
 */
export const getUpdatedStateForEventsTrail = (
  state: AnalyticsTracker,
  indexOfDataToBeUpdated: number,
  dataToBeUpdated: TrackingData['productIdsForEventsLogged'],
  productId: string,
  trailName: 'productsClicked' | 'productsAddedToCart' | 'orderCreatedForProducts',
) => {
  /**
   * Type casting to set to remove duplicates and adding ids to the list
   */
  const updatedEventsTrail = new Set(dataToBeUpdated?.[trailName]?.concat(productId));
  return [
    ...state.ctRecommendations.slice(0, indexOfDataToBeUpdated),
    {
      ...state.ctRecommendations[indexOfDataToBeUpdated],
      productIdsForEventsLogged: {
        ...dataToBeUpdated,
        /**
         * Converting back to list because we have de-dupped the list here
         */
        [trailName]: Array.from(updatedEventsTrail),
      },
    },
    ...state.ctRecommendations.slice(indexOfDataToBeUpdated + 1),
  ];
};

/**
 *
 * @param contentfulPageId
 * @param cartData : Successful response from the useAddLineItemMutation(),
 * lineItem added to cart successfully
 * @returns : Filtered LineItem from the Cart, for the product trail, which
 * was visited from the CT recommendation placement on the home page
 */
const findLineItemFromCartData = (contentfulPageId: string, cartData: ExtendedCart) =>
  cartData.lineItems.filter((item) => item.custom?.fields.contentfulPageId === contentfulPageId);

/**
 *
 * @param analyticsTrackerData : AnalyticsTracker Context store
 * @param cartData : Successful response from the useAddLineItemMutation(),
 * lineItem added to cart successfully
 * @param productInfo : Product information of the product which is being added to cart
 * @returns : Payload for publishing 'Product Added (Added to Cart)' to segment
 */
const preparePayloadForProductAdded = (
  analyticsTrackerData: AnalyticsTracker,
  cartData: ExtendedCart,
  productInfo: ProductInfo[],
) => {
  const consentData = getConsentData();
  const lineItem = findLineItemFromCartData(productInfo[0]?.custom?.fields?.contentfulPageId, cartData);
  const [first] = findListIdforRecommendedProduct(
    analyticsTrackerData.ctRecommendations,
    productInfo[0].custom.fields.contentfulPageId,
    'productsClicked',
  );

  // assinging to a constant for better readability
  const foundProductDetailsForRecommendation = first;

  const payloadForSegmentEvent: PayloadForProductAdded = {
    event: EVENT_TYPES_FOR_CT_RECOMMENDATIONS.PRODUCT_ADDED,
    cart_id: cartData?.id,
    label: `Product Page:${productInfo[0]?.custom?.fields?.merchandisingProductName}`,
    product_id: productInfo[0]?.custom?.fields?.contentfulPageId,
    prUUID: productInfo[0]?.custom?.fields?.contentfulPageId,
    lineItem_id: lineItem[0]?.id,
    name: lineItem[0]?.custom?.fields?.localizedProductName
      || productInfo[0]?.custom?.fields?.merchandisingProductName,
    sku: productInfo[0]?.sku,
    quantity: lineItem[0]?.quantity,
    currency: lineItem[0]?.price?.value?.currencyCode,
    price: ((lineItem[0]?.taxedPrice?.totalNet?.centAmount) || 0) / 100,
    value: ((lineItem[0]?.taxedPrice?.totalGross.centAmount) || 0) / 100,
    taxRate: lineItem[0]?.taxRate?.amount,
    tax: ((lineItem[0]?.taxedPrice?.totalTax?.centAmount) || 0) / 100,
    ...(foundProductDetailsForRecommendation
      && { list_id: foundProductDetailsForRecommendation?.trackingIds?.analyticsId }),
    recommendationsMetadata: {
      recommendation_id: foundProductDetailsForRecommendation?.trackingIds?.recommendationId,
      placement_id: foundProductDetailsForRecommendation?.trackingIds?.placementId,
    },
    serviceKey: lineItem[0]?.custom?.fields?.serviceKey,
  };

  if (consentData?.statistics) {
    /**
     * ajs_user_id in LS and account.id has same values
     * reading the value from the AnalyticsTracker conext, the value is available there anyway
     * to avoid window.analytics method call
     */
    payloadForSegmentEvent.userId = foundProductDetailsForRecommendation?.trackingIds?.userId || ajsUserId;
  } else {
    payloadForSegmentEvent.anonymousId = anonymousId;
  }
  return payloadForSegmentEvent;
};

/**
 *
 * @param analyticsTrackerData : AnalyticsTracker Context store
 * @param cartData : Successful response from the useAddLineItemMutation(),
 * lineItem added to cart successfully
 * @param productInfo : Product information of the product which is being added to cart
 * @param dispatchToAnalyticsTracker : dispatch to AnalyticsTrackerContext
 */
export const publishEventToSegmentForAddedToCart = (
  analyticsTrackerData: AnalyticsTracker,
  cartData: ExtendedCart,
  productInfo: ProductInfo[],
  dispatchToAnalyticsTracker: Function,
) => {
  /**
   * If Cart data is empty, don't publish segment event or track unnecessarily
   */
  if (cartData.lineItems.length > 0) {
    const productId = productInfo[0]?.custom?.fields?.contentfulPageId;
    const placementInfo = findListIdforRecommendedProduct(analyticsTrackerData.ctRecommendations, productId, 'productsClicked');
    const payloadForProductAdded = preparePayloadForProductAdded(analyticsTrackerData, cartData, productInfo);
    publishEventToSegment(EVENT_TYPES_FOR_CT_RECOMMENDATIONS.PRODUCT_ADDED, payloadForProductAdded);

    /**
     * If products are not viewed from any recommendations placement, do not track anything
     */
    if (placementInfo.length > 0) {
      dispatchToAnalyticsTracker('CT_ADD_NEW_TRACKING_DATA_FOR_EVENTS_TRAIL', {
        productId: productInfo[0]?.custom?.fields?.contentfulPageId,
        placementId: placementInfo[0]?.trackingIds?.placementId,
        trailName: 'productsAddedToCart',
      });
    }
  }
};

/**
 *
 * @param order : Placed order
 * @param locale
 * @param analyticsTrackerData : AnalyticsTracker Context store
 * @returns : list of products/lineItems ordered with appropriate keys for the event payload
 */
const transformLineItemKeysForPayload = (
  order: Order,
  locale: string,
  analyticsTrackerData: AnalyticsTracker,
) => {
  const transformedProductsMapForPayload = order?.lineItems.map((item) => {
    const productInAnalyticsTracker = findListIdforRecommendedProduct(
      analyticsTrackerData.ctRecommendations,
      item?.custom?.fields?.contentfulPageId,
      'productsAddedToCart',
    );
    const analyticsId = productInAnalyticsTracker[0]?.trackingIds?.analyticsId;
    return {
      product_id: item?.custom?.fields?.contentfulPageId,
      prUUID: item?.custom?.fields?.contentfulPageId,
      name: item?.custom?.fields?.localizedProductName,
      sku: item?.variant?.sku,
      category: getCategoryName(item, locale),
      quantity: item?.quantity,
      currency: item?.price?.value?.currencyCode,
      price: (item?.price?.value?.centAmount || 0) / 100,
      ...(analyticsId && { list_id: analyticsId }),
      position: 2,
    };
  });
  return transformedProductsMapForPayload;
};

/**
 *
 * @param order : Placed order
 * @param locale
 * @param analyticsTrackerData : AnalyticsTracker Context store
 * @returns : Payload for publishing 'Order Created' to segment
 */
const preparePayloadForOrderCreated = (
  order: Order,
  locale: string,
  analyticsTrackerData: AnalyticsTracker,
) => {
  // TODO(consent): check if user has given consent (analytics) to tracking (useConsent hook)
  const consentData = getConsentData();
  const productsInOrder = transformLineItemKeysForPayload(order, locale, analyticsTrackerData);
  return {
    event: EVENT_TYPES_FOR_CT_RECOMMENDATIONS.ORDER_CREATED,
    cart_id: order?.cart?.id,
    order_id: order?.orderNumber,
    label: 'Order Completion Page',
    total: (order?.totalPrice?.centAmount || 0) / 100,
    shipping: (order?.shippingCosts?.totalGross?.centAmount || 0) / 100,
    tax: ((order?.taxedPrice?.totalTax?.centAmount || 0) / 100),
    discount: (order?.totalCustomerDiscount?.totalGross?.centAmount || 0) / 100,
    currency: order?.totalPrice?.currencyCode,
    products: productsInOrder,
    ...(!consentData?.statistics && { anonymousId }),
    /**
     * ajs_user_id, account.id, window.analytics.user().id() has same value
     * ajs_user_id will always have data here, because order creation is not allowed without login
     */
    ...(consentData?.statistics && { userId: ajsUserId }),
  };
};

/**
 *
 * @param order : Placed order
 * @param analyticsTrackerData : AnalyticsTracker Context store
 * @param dispatchToAnalyticsTracker : dispatch to AnalyticsTrackerContext
 * @param locale
 */
export const publishEventToSegmentForOrderCreated = (
  order: Order,
  analyticsTrackerData: AnalyticsTracker,
  dispatchToAnalyticsTracker: Function,
  locale: string,
) => {
  const payloadForOrderCreated = preparePayloadForOrderCreated(order, locale, analyticsTrackerData);
  /**
   * If there are no products for tracked or no tracked events don't do anything
   */
  if (payloadForOrderCreated.products.length > 0) {
    publishEventToSegment(EVENT_TYPES_FOR_CT_RECOMMENDATIONS.ORDER_CREATED, payloadForOrderCreated);
    /**
     * if an order is created, that marks the end of the event tracking trail,
     * the recommendation placement has to be viewed again in-order to start the analyticsTracker trail(again)
     * clearing the tracking data
     */
    dispatchToAnalyticsTracker('CT_CLEAR_TRACKING_DATA');
  }
};
