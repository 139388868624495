import React from 'react';
import Helmet from 'react-helmet';
import { nanoid } from 'nanoid';
import {
  getFromLocalStorage,
  LS_KEYS,
  setInLocalStorage,
  windowPresent,
} from '../../helpers/window';
import ClientOnly from '../ClientOnly';

const segmentWriteKey = process.env.GATSBY_SEGMENT_WRITE_KEY;

if (!segmentWriteKey) {
  throw new Error('Env var not set: GATSBY_SEGMENT_WRITE_KEY');
}

let anonymousId: string | undefined;

if (windowPresent) {
  anonymousId = getFromLocalStorage(LS_KEYS.ANONYMOUS_ID);
  if (!anonymousId) {
    anonymousId = nanoid();
    setInLocalStorage(LS_KEYS.ANONYMOUS_ID, anonymousId);
  }
}

// TODO(consent): check if user has given consent (marketing) to tracking (useConsent hook)
const SegmentScript = () => (
  <ClientOnly>
    <Helmet>
      <script>
        {`
            !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentWriteKey}";analytics.SNIPPET_VERSION="4.15.2";
            analytics.load("${segmentWriteKey}");
            analytics.setAnonymousId('${anonymousId}');
            analytics.ready(function () {
              window.dispatchEvent(new Event('SegmentAnalyticsReady'));
            });
            }}();
          `}
      </script>
    </Helmet>
  </ClientOnly>
);

export default SegmentScript;
