import React from 'react';
import type { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { CartProvider } from '@printdeal/checkout-frontend';
import { SegmentationProvider } from '@printdeal/segmentation/react';
import { Root } from '@printdeal/ui-library';
import { ConsentProvider } from '@printdeal/consent-management/react';
import { actions as authActions } from '../store/slices/authSlice';
import store from '../store';
import SegmentScript from './segmentation/SegmentScript';
import SegmentProvider from './segmentation/SegmentProvider';
import AnalyticsTrackerProvider from './AnalyticsTracker/AnalyticsTrackerProvider';
import { getConfiguration } from '../helpers/Configuration';

import '../provide-configuration-values';
import '../provide-implementations';
import AuthCompatibilityComponent from './authentication/AuthCompatibilityComponent';

import queryClient from '../queryClient';
import { MopinionScript } from './MopinionScript';

const RootElementWrapper = ({ element }: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) => {
  // Immediately authenticate on first load
  store.dispatch(authActions.authenticate());

  return (
    <React.StrictMode>
      {/* icon-sprite.svg is located in ./static/generated/ */}
      <Root
        iconSpritePath="/generated/icons-sprite.svg"
        brandSpritePath="/generated/"
        brand={getConfiguration().channel.brand}
        brandSectionBgPrimaryPath="/generated/section-branded-asset-primary.svg"
        brandSectionBgWhitePath="/generated/section-branded-asset-white.svg"
      >
        <ReduxProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <ConsentProvider providerId={process.env.GATSBY_COOKIEBOT_CBID as string}>
              <SegmentProvider>
                <SegmentationProvider>
                  <AnalyticsTrackerProvider>
                    <CartProvider>
                      {element}
                      <MopinionScript
                        deploymentId={getConfiguration().mopinion.deploymentId}
                        enabled={getConfiguration().mopinion.enabled}
                      />
                    </CartProvider>
                  </AnalyticsTrackerProvider>
                  <SegmentScript />
                </SegmentationProvider>
              </SegmentProvider>
              <AuthCompatibilityComponent />
            </ConsentProvider>
          </QueryClientProvider>
        </ReduxProvider>
      </Root>
    </React.StrictMode>
  );
};

export default RootElementWrapper;
