import axios from 'axios';
import { ErrorManagement } from '@printdeal/error-management/react';
import { Locale } from '../../types/gatsby';
import { getLanguageFromLocale } from '../../helpers/translations';

class ItpService {
  async getItp(locale: Locale) {
    // If you want to run on the local machine, replace '/${getLocaleCodeFromLocale(locale)}/itp' with 'http://localhost:3000/itp'
    try {
      const response = await axios.get(`/${getLanguageFromLocale(locale)}/itp`, {
        withCredentials: true, // Axios uses 'withCredentials' instead of 'credentials' to include cookies
      });
      return response.data;
    } catch (error) {
      ErrorManagement.exception({
        exception: error,
        tags: {
          fileLocation: 'ItpService',
          featureFunction: 'ITP',
        },
        fingerprint: ['ITP-fetch-error'],
      });
      return null;
    }
  }
}

export default ItpService;
