import Api from '../Api';
import { FilterFacets } from '../../components/algolia-search/helper/algoliaFacets';

export type Question = {
  question: string;
  attribute: string;
  values: {
    value: string;
    count: number;
  }[];
};

export type Questions = {
  questions: Question[];
};

class ProductAiService extends Api {
  async getFacetFilteringQuestions(facets: FilterFacets): Promise<Questions> {
    const { data } = await this.httpClient.post<Questions>('/facet-questions', facets);
    return data;
  }
}

export default ProductAiService;
