import { createSlice } from '@reduxjs/toolkit';
import { actions as authActions } from './authSlice';
import {
  createInitialAsyncActionState,
  createAsyncActionReducers,
} from '../utils';

const EnrichmentStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: '',
  updateCustomer: {
    isLoading: false,
    error: '',
  },
  enrichRegistration: {
    status: EnrichmentStatus.IDLE,
    error: '',
  },
  getAccount: createInitialAsyncActionState(),
  removeAddressById: {},
  customer: {
    companyName: '',
    vatId: '',
    custom: {
      fields: {
        cocNumber: '',
        isCompany: '',
      },
    },
  },
  account: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    newsletterOptIn: '',
  },
  surveyDetails: [],
  surveySuccess: false,
};

const mapCustomerDataToState = (data) => {
  const {
    addresses,
    ...customer
  } = data;

  return customer;
};

const mapAccountPayloadToState = ({ state, payload }) => ({
  id: payload.id || state.account.id,
  firstName: payload.firstName || state.account.firstName,
  lastName: payload.lastName || state.account.lastName,
  email: payload.email || state.account.email,
  newsletterOptIn: payload.newsletterOptIn || state.account.newsletterOptIn,
});

const handleGetAccountSuccess = (state, { payload }) => {
  // This method is run multiple time as people log in/browse the site.
  // Depending on how and where this method is triggered, it may not have the customer and surveyDetail data.
  const { account, customer } = payload;
  state.account = mapAccountPayloadToState({
    state,
    payload: account,
  });

  if (account.surveyDetails) {
    state.surveyDetails = account.surveyDetails;
  }

  if (customer) {
    state.customer.companyName = customer.companyName;
    state.customer.vatId = customer.vatId;
    state.customer.custom.fields.cocNumber = customer.cocNumber;
    state.customer.custom.fields.isCompany = customer.isCompany;
  }
};

/**
 * Customer slice
 */
export const { reducer, actions } = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    ...createAsyncActionReducers({
      type: 'getAccount',
      onSuccess: handleGetAccountSuccess,
    }),
    ...createAsyncActionReducers({
      type: 'updateAccount',
      onSuccess: handleGetAccountSuccess,
    }),
    getCustomer: () => { },
    getCustomerRequest: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    getCustomerSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = '';
      state.customer = mapCustomerDataToState(payload.customer);
    },
    getCustomerFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    updateCustomerRequest: (state) => {
      state.updateCustomer = {
        isLoading: true,
        error: '',
      };
    },
    updateCustomerSuccess: (state, { payload }) => {
      state.updateCustomer = {
        isLoading: false,
        error: '',
      };
      state.customer = mapCustomerDataToState(payload.customer);
    },
    updateCustomerFailure: (state, { payload }) => {
      state.updateCustomer = {
        isLoading: false,
        error: payload.error,
      };
    },
    enrichRegistration: () => { },
    enrichRegistrationRequest: (state) => {
      state.enrichRegistration = {
        status: EnrichmentStatus.LOADING,
        error: '',
      };
    },
    enrichRegistrationSuccess: (state, { payload }) => {
      state.enrichRegistration = {
        status: EnrichmentStatus.LOADED,
        error: '',
      };
      state.customer = mapCustomerDataToState(payload.customer);
      state.account = mapAccountPayloadToState({ state, payload: payload.account });
    },
    enrichRegistrationFailure: (state, { payload }) => {
      state.enrichRegistration = {
        status: EnrichmentStatus.ERROR,
        error: payload.error,
      };
    },
    updateSurveyDetails: () => { },
    updateSurveyDetailsSuccess: (state, { payload }) => {
      state.surveyDetails = payload.surveyDetails;
    },
  },
  extraReducers: {
    [authActions.logoutRequest]: () => initialState,
    [authActions.loginSuccess]: handleGetAccountSuccess,
  },
});

/**
 * Selectors
 */

export const selectors = {
  getCustomerId: ({ customer }) => customer?.data?.id || null,
  getCustomerGroupId: ({ customer }) => customer?.customer?.customerGroup?.id || null,
  getCustomerData: ({ customer }) => customer.customer,
  getCustomerFirstName: ({ customer }) => customer.account.firstName || '',
  getCustomerLastName: ({ customer }) => customer.account.lastName || '',
  getCustomerEmail: ({ customer }) => customer.customer.email || '',
  getCustomerNumber: ({ customer }) => customer.customer.customerNumber || '',
  getCustomerType: ({ customer }) => customer.customer.customerType || '',
  getAccountId: ({ customer }) => customer.account.id || '',
  getSurveyDetails: ({ customer }) => customer.surveyDetails || [],
  isNewsletterOptIn: ({ customer }) => customer.account.newsletterOptIn || '',
  isCustomerLoaded: ({ customer }) => customer.isLoaded,
  isAccountLoaded: ({ customer }) => customer.account.id?.length > 0 || false,
  isCustomerCompany: ({ customer }) => customer.customer?.custom?.fields?.isCompany || false,
};
