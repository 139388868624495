import { ErrorManagement } from '@printdeal/error-management/react';
import Api from '../Api';

class SurfaceDependencyService extends Api {
  getTechnicalDrawingPdfUrl = async (
    productId: string,
    selections: { [key: string]: string },
    locale: string,
    version?: number,
  ): Promise<{ status: 'READY' | 'IN_PROGRESS', resultUrl: string }> => {
    try {
      const { data } = await this.httpClient.post(
        '/v2/templates',
        {
          productId,
          version,
          selections,
          accountId: 'noS5wU4XH2WRmkaqKqTpfH',
          templateParameters: {
            requiredFiles: ['pdf'],
            outputFormat: 'zip',
            locale,
          },
        },
      );
      return data;
    } catch (error) {
      ErrorManagement.exception({
        exception: error,
        extra: {
          productId,
          version,
          selections: JSON.stringify(selections),
        },
        tags: {
          component: 'summary',
          fileLocation: 'SurfaceDependencyService.ts',
          featureFunction: 'getTechnicalDrawingPdfUrl',
        },
        fingerprint: ['printdeal-pdf-template-download'],
      });
      throw error;
    }
  };
}

export default SurfaceDependencyService;
