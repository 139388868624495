import React, { useEffect } from 'react';
import { useSegmentation } from '@printdeal/segmentation/react';
import { useConsent } from '@printdeal/consent-management/react';
import { SentryHelper } from '../helpers/SentryHelper';

interface MopinionScriptProps {
  deploymentId: string;
  enabled: boolean;
}
declare global {
  interface Window {
    Pastease?: {
      load: (id: string) => void;
    };
    isReseller?: boolean;
  }
}

export const MopinionScript: React.FC<MopinionScriptProps> = ({ deploymentId, enabled }) => {
  const { data: segmentationData } = useSegmentation();
  const { isReseller } = segmentationData ?? { isReseller: false };
  const isWindow = typeof window !== 'undefined';

  const scriptId = 'mopinion-script';
  const consent = useConsent();
  const hasMarketingConsent = consent?.marketing;

  useEffect(() => {
    /**
   * Set a global variable `window.isReseller` so that external tools like Mopinion
   * can check if the user is a reseller.
   */
    if (isWindow) {
      window.isReseller = isReseller;
    }
  }, [isReseller, isWindow]);

  useEffect(() => {
    if (!isWindow) {
      return;
    }
    if (enabled && !isReseller && hasMarketingConsent) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;
      script.src = 'https://deploy.mopinion.com/js/pastease.js';
      script.onload = () => {
        if (window.Pastease) {
          try {
            window.Pastease.load(deploymentId);
          } catch (e) {
            SentryHelper.exception({
              e,
              extra: {
                source: 'MopinionScript',
                reason: 'Pastease.load failed',
                deploymentId,
              },
              tags: {
                type: 'mopinion-script-error',
              },
            });
          }
        }
      };
      document.head.appendChild(script);
    }
  }, [isReseller, enabled, hasMarketingConsent, isWindow, deploymentId]);

  return null;
};
