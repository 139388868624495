module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NWCPDK4","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Drukwerkdeal","short_name":"Drukwerkdeal","start_url":"/","lang":"nl-NL","background_color":"#e8712c","theme_color":"#e8712c","display":"standalone","legacy":false,"icon":"./node_modules/@printdeal/brand/printdeal/images/production-android-chrome-512x512.png","localize":[{"start_url":"/","lang":"nl"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7347fd0fd37983b1d10286fd98b732ab"},
    },{
      plugin: require('../plugins/gatsby-plugin-auto-refresh/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-error-management/gatsby-browser.ts'),
      options: {"plugins":[],"enabled":true,"environment":"production","url":"https://b9ea2f3344d347e5b932f22c9ccb1f92@o50002.ingest.sentry.io/1416261"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
