/* eslint-disable no-param-reassign */

/**
 * Get an objects property value by path
 * @param {string} path - Path to search for, i.e. 'object.key.anotherKey'
 * @param {object} object - Object to search in
 *
 * @return {*} - The resolved value or undefined if path does not exist in object
 */
export const getValueByPath = (path, object = {}) => {
  const keys = path.split('.');
  let index = 0;
  let value = object;
  while (value && index < keys.length) {
    value = value[keys[index]];
    index += 1;
  }
  return index === keys.length ? value : undefined;
};

/**
 * Possible status for async actions
 */
const ASYNC_ACTION_STATUS = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  error: 'error',
};

/**
 * Create initial async action state
 *
 * @param {object} state - Initial state
 *
 * @return {object} - Initial state
 */
export const createInitialAsyncActionState = (state = {}) => ({
  status: ASYNC_ACTION_STATUS.idle,
  error: '',
  ...state,
});

/**
 * Create reducers for async actions
 * @param {string} type - Async action type, i.e. 'getUser'
 * @param {function} onRequest - Callback function for request type action
 * @param {function} onSuccess - Callback function for success type action
 * @param {function} onFailure - Callback function for error type action
 *
 * @return {object} - Reducers for async action with request, success and error types
 */
export const createAsyncActionReducers = ({
  type,
  onRequest = () => { },
  onSuccess = () => { },
  onFailure = () => { },
}) => ({
  [type]: () => { },
  [`${type}Request`]: (state, action) => {
    state[type] = {
      ...state[type],
      status: ASYNC_ACTION_STATUS.loading,
      error: '',
    };
    onRequest(state, action);
  },
  [`${type}Success`]: (state, action) => {
    state[type] = {
      ...state[type],
      status: ASYNC_ACTION_STATUS.success,
    };
    onSuccess(state, action, type);
  },
  [`${type}Failure`]: (state, action) => {
    state[type] = {
      ...state[type],
      status: ASYNC_ACTION_STATUS.error,
      error: action?.payload?.error || '',
    };
    onFailure(state, action, type);
  },
});
