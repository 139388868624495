import {
  Prices,
  PriceAttribute,
} from '@printdeal/product-selector';
import Api from '../Api';

export interface ProductConfigurationPrices {
  productPageId: string;
  configuration: PriceAttribute[];
  prices: Prices;
}

class DynamicProductRoutesService extends Api {
  async getCheapestDynamicProductRoute(productpageId: string): Promise<unknown> {
    const { data } = await this.httpClient.get<unknown>(`/product-routes/cheapest/${productpageId}`);
    return data;
  }

  async getMostPopularDynamicProductRoute(productpageId: string): Promise<unknown> {
    const { data } = await this.httpClient.get<unknown>(`/product-routes/popular/${productpageId}`);
    return data;
  }

  async saveProductConfigurationPrices(payload: ProductConfigurationPrices): Promise<unknown> {
    const { data } = await this.httpClient.post<unknown>('/price-tables', payload);
    return data;
  }

  async invalidateProductConfigurationPrices(productpageId: string): Promise<void> {
    const { data } = await this.httpClient.delete<void>(`/price-tables/${productpageId}`);
    return data;
  }
}

export default DynamicProductRoutesService;
