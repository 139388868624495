import axios, { AxiosError } from 'axios';
import { addPageViewEntry } from '@printdeal/data-layer-manager';
import { jwtDecode } from 'jwt-decode';
import type { GatsbyBrowser } from 'gatsby';
import { ErrorManagement } from '@printdeal/error-management/react';
import supportAutoTranslators from './src/polyfills/support-auto-translators';
import PageElementWrapper from './src/components/PageElementWrapper';
import RootElementWrapper from './src/components/RootElementWrapper';
// @ts-expect-error Need to convert to TS
import { AuthDataLayerHelper } from './src/helpers/dataLayerHelpers/AuthDataLayerHelper';
import { clearBodyScrollLocks } from './src/helpers/scroll-helper';
import { getFromLocalStorage, LS_KEYS } from './src/helpers/window';
import { dedupeTitle } from './src/helpers/dedupe-title';
import { type PrintdealAuthTokenPayload } from './src/helpers/auth';

// Import main CSS bundle
import './src/css/global.css';
import './src/style.css';

// Polyfills
import 'core-js/actual/object/has-own';

/**
 * Hacks the DOM Node API to support auto-translators like Google Translate in React.
 * @see https://drukwerkdeal.atlassian.net/browse/SE-108
 * @see https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
supportAutoTranslators();

/**
 * Wrap root and page elements
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 */
export const wrapRootElement = RootElementWrapper;
export const wrapPageElement = PageElementWrapper;

/**
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#disableCorePrefetching
 */
export const disableCorePrefetching: GatsbyBrowser['disableCorePrefetching'] = () => true;

/**
 * Routing
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onPreRouteUpdate
 */
export const onPreRouteUpdate = () => {
  // Clear all body scroll locks
  clearBodyScrollLocks();
};

/** @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate */
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (AuthDataLayerHelper.enableLoginEventOnRouteUpdate()) {
    const customerNumber = getFromLocalStorage(LS_KEYS.CUSTOMER_NUMBER);
    // TODO(consent): Check if we have the correct consent level (marketing) to store/send this data
    const customerType = getFromLocalStorage(LS_KEYS.CUSTOMER_TYPE);
    const authToken = getFromLocalStorage(LS_KEYS.AUTH_TOKEN);

    if (customerNumber && customerType && authToken) {
      const customerTokenData = jwtDecode<PrintdealAuthTokenPayload>(authToken);

      if (customerTokenData.account && customerTokenData.account.id) {
        AuthDataLayerHelper.dispatchDataLayerEventOnLogin(
          customerTokenData,
          { customerNumber, customerType },
          true,
        );
      }
    }
  }
  // Search result events are handled in searchResult.js as
  // query changes are not detected by `onRouteUpdate`
  if (location.pathname !== '/nl/result') {
    addPageViewEntry({ path: location.pathname, query: location.search });
  }

  // prevLocation is `null` if we're on the first page
  if (prevLocation) {
    dedupeTitle(location.pathname, prevLocation.pathname);
  }
};

/** @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll */
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps }) => {
  try {
    if (routerProps.location.hash) {
      const { hash } = routerProps.location;
      const element = document.querySelector(decodeURI(hash));
      if (element !== null) {
        // Wrapping in setTimeout because Gatsby somehow interferes with our scrollIntoView() call
        // and scrolls to the wrong position.
        // Adding the timeout allows Gatsby to first (invisibly) scroll to the incorrect position
        // after which we can scroll to the correct position.
        setTimeout(() => {
          element.scrollIntoView({ block: 'start' });
        }, 100);
      }
      return false;
    }
    return true;
  } catch (error) {
    console.error(error);
    return true;
  }
};

/**
 * Called when the Gatsby browser runtime first starts.
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
 */
export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
  if (process.env.GATSBY_CHECK_CUSTOM_DOMAIN === 'true') {
    try {
      // Check whether the user can access our custom domains
      await axios.get(`${process.env.GATSBY_AUTH_ENDPOINT_CUSTOM}/status`);
    } catch (error) {
      const { response } = error as AxiosError;

      // Log error to Sentry
      ErrorManagement.exception({
        exception: 'Unable to reach custom authentication domain',
        tags: {
          fileLocation: 'gastby-browser',
          featureFunction: 'testCustomDomain',
        },
        fingerprint: ['gastby-browser-test-custom-domain'],
        extra: {
          error: JSON.stringify(error),
          statusCode: response?.status,
        },
      });
    }
  }
};
