declare global {
  interface Window {
    CookieConsent?: any;
  }
}

/**
 * @deprecated Types are incomplete, use `useConsent()` instead
 */
export interface ConsentData {
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
}

/**
 * @deprecated Use `useConsent()` instead
 */
export function getConsentData(): ConsentData {
  return {
    // Defaults based on Cookiebot's own defaults, described here: https://www.cookiebot.com/en/developer/
    necessary: typeof window.Cookiebot?.consent.necessary === 'boolean' ? window.Cookiebot.consent.necessary : true,
    preferences: window.Cookiebot?.consent.preferences || false,
    statistics: window.Cookiebot?.consent.statistics || false,
    marketing: window.Cookiebot?.consent.marketing || false,
  };
}

/**
 * @deprecated Use `useConsent()` instead
 */
export const cookieDialogWasShown = () => typeof window.Cookiebot?.consent.method === 'string';
