import { configurationProvider } from '@printdeal/configuration';
import {
  getAddressCountries,
  getBaseUrl,
  getConfiguration,
  getPUPDefaultCountry,
  getPUPDefaultPostalCode,
  getSplitShippingCountries,
} from './helpers/Configuration';

configurationProvider.provideValues({
  // Base URLs
  ADDRESS_API_BASE_URL: getBaseUrl('ADDRESS_API'),
  AUTHENTICATION_API_BASE_URL: getBaseUrl('AUTHENTICATION_API'),
  CUSTOMER_API_BASE_URL: getBaseUrl('CUSTOMER_API'),
  ORDER_API_BASE_URL: getBaseUrl('ORDERS_API'),
  PAYMENT_V2_API_BASE_URL: getBaseUrl('PAYMENT_API'),
  QUOTES_API_BASE_URL: getBaseUrl('QUOTES_API'),
  ECOMMERCE_CART_API_BASE_URL: getBaseUrl('ECOMMERCE_CART_API'),
  ECOMMERCE_ORDER_API_BASE_URL: getBaseUrl('ECOMMERCE_ORDER_API'),
  VAT_VALIDATION_API_BASE_URL: getBaseUrl('VAT_VALIDATION_API'),
  ZIP_CODE_API_BASE_URL: getBaseUrl('ZIP_CODE_API'),
  SEGMENTATION_API_BASE_URL: getBaseUrl('SEGMENTATION_API'),

  // Countries
  ADDRESS_COUNTRIES: getAddressCountries(),
  SPLIT_SHIPPING_COUNTRIES: getSplitShippingCountries(),
  PUP_DEFAULT_POSTAL_CODE: getPUPDefaultPostalCode(),
  PUP_DEFAULT_COUNTRY: getPUPDefaultCountry(),
  OFFICE_ADDRESS_COUNTRY: getConfiguration().officeAddress.countryCode,
  OFFICE_ADDRESS_POSTAL_CODE: getConfiguration().officeAddress.postalCode,
  DEFAULT_SHIPPING_COUNTRY: getConfiguration().defaultShippingCountry,
  DEFAULT_PAYMENT_METHODS: getConfiguration().finance.paymentMethods.default,

  // Process environment variables
  STORE_KEY: process.env.GATSBY_STORE as string,

  CONTENTFUL_SPACE_ID: process.env.GATSBY_CONTENTFUL_SPACE_ID as string,
  CONTENTFUL_ACCESS_TOKEN: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN as string,
  CONTENTFUL_ENVIRONMENT: process.env.GATSBY_CONTENTFUL_ENVIRONMENT as string,

  // DWD space details for shared selectors
  CONTENTFUL_DWD_SPACE_ID: process.env.GATSBY_CONTENTFUL_DWD_SPACE_ID as string,
  CONTENTFUL_DWD_ACCESS_TOKEN: process.env.GATSBY_CONTENTFUL_DWD_ACCESS_TOKEN as string,

  AUTH_LAYER_API_BASE_URL: getBaseUrl('AUTH_LAYER_API'),
  PRODUCT_API_BASE_URL: getBaseUrl('PRODUCT_API'),
  PRODUCT_VALIDATION_BASE_URL: process.env.GATSBY_PRODUCT_VALIDATION_BASE_URL as string,
  PRODUCT_VALIDATION_ENV: process.env.GATSBY_PRODUCT_VALIDATION_ENV as string,

  PORTAL_ID: process.env.GATSBY_PORTAL_ID as string,
  GOOGLE_MAPS_API_KEY: process.env.GATSBY_PUP_GOOGLE_MAPS_API_KEY as string,

  PICKUP_POINTS_ENABLED: getConfiguration().pickupPoint.enabled,
  SPLIT_SHIPPING_ENABLED: getConfiguration().splitShipping.enabled,
  DELIVERY_OPTIONS_ENABLED: true,
});
