import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@printdeal/ui-library';

import ChatContext from './ChatContext';
import useZendeskChat from './useZendeskChat';

import { dispatchDataLayerEventOnOpenChat } from '../../../helpers/dataLayerHelpers/ChatWidgetDataLayerHelper';

const ChatButton = () => {
  const intl = useIntl();
  const { chatStatus, chatConfig: { enabled } } = useContext(ChatContext);
  const { openChat } = useZendeskChat({
    onOpen: () => {
      dispatchDataLayerEventOnOpenChat('chat_button');
    },
  });

  if (!enabled || chatStatus === 'initial' || chatStatus === 'open' || chatStatus === 'unavailable') {
    return null;
  }

  if (chatStatus === 'loading') {
    return (
      <Button
        type="button"
        iconBefore="chat"
        className="pd-fixed pd-bottom-4 pd-left-4 pd-z-20"
        isLoading
      >
        { intl.formatMessage({ id: 'general.chat.fake.button.chatting', defaultMessage: 'Chat' })}
      </Button>
    );
  }

  // Which leaves chatStatus = 'waiting(UserInteraction)'
  return (
    <Button
      type="button"
      onClick={openChat}
      iconBefore="chat"
      className="pd-fixed pd-bottom-4 pd-left-4 pd-z-20"
    >
      { intl.formatMessage({ id: 'general.chat.fake.button.chatting', defaultMessage: 'Chat' })}
    </Button>
  );
};

export default ChatButton;
