import type { GatsbyBrowser, PluginOptions } from 'gatsby';
import { ErrorManagement, type ExceptionEvent } from '@printdeal/error-management/react';

import { getDecodedToken, isAuthenticated } from '../../src/helpers/auth';
import { getCurrentVersion, getVersions } from '../../src/helpers/version-check/get-versions';

type Options = PluginOptions & {
  enabled: boolean
  environment: string
  url: string
};

/**
 * If the current user is logged in, send the account ID and customer ID as part of the event
 */
const appendAccountAndCustomerId = async (event: ExceptionEvent) => {
  if (isAuthenticated()) {
    const user = getDecodedToken();
    if (user && user.account && user.customer) {
      return {
        ...event,
        tags: {
          ...event.tags,
          account_id: user.account.id,
          customer_id: user.customer.id,
        },
      };
    }
  }
  return event;
};

/**
 * Add the current and latest build versions to the tags
 */
const appendBuildVersions = async (event: ExceptionEvent) => {
  let extraTags;

  try {
    const { current, latest } = await getVersions();
    extraTags = { currentVersion: current, latestVersion: latest };
  } catch (error) {
    extraTags = { versionLoadFailure: true, currentVersion: getCurrentVersion() };
  }

  return {
    ...event,
    tags: {
      ...event.tags,
      ...extraTags,
    },
  };
};

/**
 * If the error is caused by a version mismatch, reload the page instead of sending an error.
 */
const reloadOnVersionMismatch = async (event: ExceptionEvent) => {
  const { current, latest, versionLoadFailure } = event.tags;

  if (!versionLoadFailure && current !== latest) {
    window.location.reload();
    return null;
  }

  return event;
};

/**
 * Skip any errors that are caused by page data not being loaded. This is usually indicative of a user navigating away
 * from the site and should not lead to an error.
 */
const ignorePageDataLoadErrors = async (event: ExceptionEvent) => {
  const pageDataRegex = /We couldn't load "\/page-data\/sq\/d\/\d+\.json"/;
  const message = event.exception?.message;
  const { traces } = event;
  if (message && pageDataRegex.test(message) && traces) {
    const pageDataWithoutResponse = traces.some(
      ({ category, data }) => category === 'xhr' && data && message.includes(data.url) && data.status_code === 0,
    );

    if (pageDataWithoutResponse) {
      return null;
    }
  }

  return event;
};

/**
 * Called when the Gatsby browser runtime first starts.
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
 */
export const onClientEntry: GatsbyBrowser['onClientEntry'] = async (_, { enabled, environment, url }: Options) => {
  if (enabled) {
    ErrorManagement.init({
      url,
      // @ts-expect-error
      release: __DYNAMIC_RELEASE_STRING__,
      environment,
      debugIdLocalStorageName: 'debugId',
      ignoreErrors: [
        // See https://printdeal.slack.com/archives/C07SDK6PA05
        'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
        'Hydration Error',
      ],
      filters: {
        blockedGoogleAds: true,
        axiosErrorWithoutResponse: true,
        xhrWithoutResponseInBreadcrumbs: false,
        additionalFilters: [
          appendAccountAndCustomerId,
          appendBuildVersions,
          reloadOnVersionMismatch,
          ignorePageDataLoadErrors,
        ],
      },
    });
  }
};
